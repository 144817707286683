import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC1 from "../../../assets/image/why-us/247customer-support.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-32 pb-5 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="order-2 order-lg-1" lg="5" xs="10" data-aos="fade-right" data-aos-duration="600">
              <div className="content-img">
                <img src={imgC1} alt="" className="w-100" />
              </div>
            </Col>
            <Col className="order-1 order-lg-2 pl-xl-21" xs="10" lg="6">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-3 mb-11">
                  Support and listen to you 24/7
                </h2>
                <p className="gr-text-8">
                  We're passionate about helping you and your business thrive. We care about your success and your time.
                </p>
                <p className="gr-text-8">
                  That's why the Camelo team is available 24/7 to provide the support that you need, and willing to enhance the user experience. 💪❤️
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
