import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";

import imgScheduling from "../../../assets/image/why-us/employee-scheduling-icon.svg";
import imgMessaging from "../../../assets/image/why-us/team-messaging-icon.svg";
import imgTasking from "../../../assets/image/why-us/task-management-icon.svg";
import imgTimeClock from "../../../assets/image/why-us/time-clocks-icon.svg";
import imgIntegration from "../../../assets/image/why-us/gears-icon.svg";
import imgTimesheets from "../../../assets/image/why-us/timesheets-icon.svg";

const Features = () => {
  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="feature-section pt-6 pt-lg-6">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="7" xl="6">
              <div className="section-title text-center mb-13 mb-lg-23">
                <h2 className="title gr-text-3">
                  We made this app to solve your problems.
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="6" lg="4" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                  <img className="" src={imgScheduling} alt="/" width="42" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-5">Employee Scheduling</h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Create cost-optimized schedules and share instant updates easily.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                  <img className="" src={imgMessaging} alt="/" width="42" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-5">Team Messaging</h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Never miss important announcements and updates.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                  <img className="" src={imgTimeClock} alt="/" width="42" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-5">Time & Attendance</h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Turn any phone into time clock, with location capture and biometric recognition.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                  <img className="" src={imgTasking} width="42" alt="/" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-5">Task Management</h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Assign tasks to your staff, get notified when they're done.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                  <img className="" src={imgTimesheets} width="42" alt="/" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-5">Timesheets</h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Easily track employee hours and export timesheets for seamless payroll processing.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                  <img className="" src={imgIntegration} width="42" alt="/" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-5">Integrations</h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Integrate with payroll and other HR systems.
                  </p>
                </div>
              </div>
            </Col>
            <div className="col-12">
              <div className="border-sm-divider dark text-center mt-lg-8"></div>
            </div>
          </Row>
          <Row>
            <Col xs="12">
              <div className="text-center">
                <Link
                  to="/features"
                  css={`
                      cursor: pointer;
                    `}
                  className="btn-link with-icon  gr-text-8  py-12"
                >
                  Product Overview{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Features;
