import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import imgC1 from "../../../assets/image/why-us/one-app-to-track-time-crew.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-19 pb-5 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="order-2 order-lg-1" lg="5" xs="10">
              <div className="content-img" data-aos="fade-right" data-aos-duration="600">
                <LazyLoadImage
                  alt="Eliminate manual spreadsheets and time tracking"
                  src={imgC1}
                  className="w-100"
                />
              </div>
              <div className="border-top mt-8 py-8 border-bottom" data-aos="fade-up" data-aos-duration="600">
                <figure class="text-end pb-0 mb-0">
                  <blockquote class="blockquote">
                    <p><strong>No shifts are forgotten, no tasks are unfinished, and I always know what my staff are doing.</strong></p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <strong>Dhiraj Dalal</strong>, Business Owner, <cite title="Source Title">Safari Lawn Care</cite>
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col className="order-1 order-lg-2 pl-xl-21" xs="10" lg="6">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-4 mb-11">
                  Eliminate manual spreadsheets and time tracking
                </h2>
                <p className="gr-text-8">
                  With Camelo, you don't have to face the hassle of manual spreadsheets and time tracking anymore. Everything is in one app, fits in your pocket, and is easy to adjust in minutes.
                </p>
                <p className="gr-text-8">
                  Camelo enables you to create staff schedules in a few touches. With geolocation and biometric validation, you can track employees' attendance and work hours precisely. Managers can approve and later export timesheets for payroll.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
