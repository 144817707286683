import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col} from "react-bootstrap";

import imgCL from "../../../assets/image/why-us/simplify-business-admin.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div id="elWhyUs" className="bg-default-9 pt-13 pt-md-25 pb-13 pb-md-25 mx-md-6 rounded-10 mb-6">
        <Container>
          <Row className="align-items-center">
            <Col
              md="6"
              lg="6"
              className="mb-n15"
            >
              <div
                className="content-img text-left pr-xl-13 ml-n8"
                data-aos="fade-right"
                data-aos-duration="1100"
              >
                <img src={imgCL} alt="" className="w-100 rounded-10" />
              </div>
              <div
                className="pt-7"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <figure class="text-end">
                  <blockquote class="blockquote">
                    <p><strong>It was so frustrating to update our schedules and timesheets manually. Everything is now quick, convenient, and precise.</strong></p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <strong>Aletha Osinski</strong>, Business Owner, <cite title="Source Title">Sprinkles Bakery</cite>
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col xs="11" sm="12" md="6" lg="5">
              <div className="pl-lg-3 pl-xl-12 mt-12 mt-md-0">
                <h2 className="title gr-text-4 mb-11">
                  Simplify your business admin
                </h2>
                <p className="gr-text-8 gr-text-color-opacity">
                  As your business grows, hires more employees, and expands to multiple locations, the admin work can become more mundane, time-consuming, and stressful. Camelo simplifies the admin process, saves you hours of your time, and removes stress from your life.
                </p>
                <p className="gr-text-8 gr-text-color-opacity">
                  Camelo gives you a bird's eye view of your workforce—who’s on the clock, where they’re working, and what they’re working on. With controlled access, only those with your permission can create and assign shifts. Managers can prevent time theft via GPS, Wi-Fi, and facial validation.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
