import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";

import Hero from "../sections/camelo/why-camelo/Hero";
import Content1 from "../sections/camelo/why-camelo/Content1";
import Content2 from "../sections/camelo/why-camelo/Content2";
import Content3 from "../sections/camelo/why-camelo/Content3";
import Content4 from "../sections/camelo/why-camelo/Content4";
import Content5 from "../sections/camelo/why-camelo/Content5";
import Content6 from "../sections/camelo/why-camelo/Content6";
import Content7 from "../sections/camelo/why-camelo/Content7";
import Content8 from "../sections/camelo/why-camelo/Content8";
import Video from "../sections/camelo/why-camelo/Video";
import Features from "../sections/camelo/why-camelo/Features";

import BottomCTA from "../sections/camelo/BottomCTA";

const WhyUsPage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="How Camelo Can Help You & Your Business"
          description="Find out how Camelo helps you simplify business admin, eliminate the
            stress of employee scheduling & manage your team through chaotic
            times."
        ></SEO>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Find out how Camelo helps you simplify business admin, eliminate the
            stress of employee scheduling & manage your team through chaotic
            times.
          </title>
          <link rel="canonical" href="https://camelohq.com/why-camelo" />
        </Helmet>

        <Hero />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Content5 />
        <Content6 />
        <Content7 />
        <Content8 />

        <div className="gradient-sections-wrapper bg-gradient-4 mt-42">
          <Video />
          <Features />
        </div>
        <BottomCTA />
      </PageWrapper>
    </>
  );
};

export default WhyUsPage;
