import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/why-us/easy-setup-and-use.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-19 pb-7 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              lg="5"
              className="order-2"
              data-aos="fade-left"
              data-aos-duration="600"
            >
              <div className="content-img text-right">
                <img src={imgC} alt="" className="w-100" />
              </div>
            </Col>
            <Col xs="10" lg="6" xl="6" className="pr-xl-21 order-1">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-3 mb-11">
                  Easy to set up.<br className="d-none d-xl-block" /> Easy to use
                </h2>
                <p className="gr-text-8 mb-11">
                  Set up employee schedule in a few touches, in a few minutes. Employers and employees can easily use the app without too much sweat and training. Save your time for more important tasks.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
