import React, {useContext} from "react";
import {Container, Row, Col} from "react-bootstrap";

import GlobalContext from "../../../context/GlobalContext";

import imgV from "../../../assets/image/why-us/employee-scheduling-time-clock-app2.svg";
import imgP from "../../../assets/image/why-us/dots-bg-pattern.svg";
import imgVW from "../../../assets/image/svg/l6-video-wave.svg";
import imgVWD from "../../../assets/image/svg/l6-video-wave-dark.svg";

const Video = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <div className="shape">
        <img src={imgVW} alt="" className="w-100 light-shape default-shape" />
        <img src={imgVWD} alt="" className="w-100 dark-shape" />
      </div>
      {/* <!-- Video Area --> */}
      <div className="video-area position-relative gr-z-index-1 mt-n29 mt-lg-n35">
        <Container>
          <Row className="justify-content-center">
            <Col xs="7">
              <div className="video-image-pattern-bottom ">
                <img className="w-100 rounded-20" src={imgV} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Video;
