import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC1 from "../../../assets/image/why-us/keep-team-on-the-same-page.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-28 pb-5 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="order-2 order-lg-1" lg="5" xs="10">
              <div className="content-img" data-aos="fade-right" data-aos-duration="600">
                <img src={imgC1} alt="" className="w-100" />
              </div>
              <div className="border-top mt-8 py-8 border-bottom" data-aos="fade-up" data-aos-duration="600">
                <figure class="text-end pb-0 mb-0">
                  <blockquote class="blockquote">
                    <p><strong>It's easy to keep my team updated on their schedules and tasks. We can drop each other messages right on Camelo without the need for another chat app.</strong></p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <strong>Jared Lebsack</strong>, Business Owner, <cite title="Beans and Cream Cafe">J&D Cafe</cite>
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col className="order-1 order-lg-2 pl-xl-21" xs="10" lg="6">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-4 mb-11">
                  Keep everyone on the same page and in touch
                </h2>
                <p className="gr-text-8">
                  New schedules and changes are notified instantly to staff's phones, so there's no need to ring round. Your staff can view their shifts and clock in/clock out with their phones.
                </p>
                <p className="gr-text-8">
                  With the team messaging feature on Camelo, you and your staff can never miss important messages, announcements, and information anymore.
                </p>
                <p className="gr-text-8">
                  Different chat channels can be created for various purposes. Whether it's a 1-on-1 private conversation or a group chat for sending memes, Camelo helps improve internal communication in your business.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
