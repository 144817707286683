import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/why-us/stay-compliant.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-28 pb-7 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              lg="5"
              className="order-2"
            >
              <div
                className="content-img text-right"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <img src={imgC} alt="" className="w-100 rounded-10" />
              </div>
              <div
                className="border-top mt-8 py-8 border-bottom"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <figure class="text-end pb-0 mb-0">
                  <blockquote class="blockquote">
                    <p><strong>The overtime alerts and records of employees' work hours are of great help. Staying compliant no longer gives me headaches.  </strong></p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <strong>Melba Sporer</strong>, Business Owner, <cite title="Grandma's Homemade Bread">Grandma's Homemade Bread</cite>
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col xs="10" lg="6" xl="6" className="pr-xl-21 order-1">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-3 mb-11">
                  Help you stay compliant
                </h2>
                <p className="gr-text-8 mb-11">
                  Labor laws and regulations are complex and tough. Managers can record, approve, and export timesheets for payroll on Camelo, ensuring that you're paying your staff correctly and complying with the laws.
                </p>
                <p className="gr-text-8 mb-11">
                  Overtime alerts save your staff from working unplanned overtime. All details are saved and stored automatically, so you have a record of your employees' work hours and your company's compliance.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
