import React from "react";
import {Link} from "react-scroll";
import {Container, Row, Col, Button, FormControl, InputGroup} from "react-bootstrap";

import imgC2 from "../../../assets/image/why-us/why-us.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 py-18 position-relative lg:min-h-vh-100 bg-gray">
        <div
          className="hero-area bg-image  mx-6 mx-lg-9 rounded-15 h-100"
          css={`
            background-color: #fff;
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="6" md="8">
                <div className="hero-content pr-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h6 className="gr-text-12 text-uppercase mb-8">
                    WHY CAMELO?
                  </h6>
                  <h1 className="title gr-text-2 mb-16">Manage your business with ease</h1>
                  <p
                    className="gr-text-6 mb-0"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-duration="900"
                  >
                    Eliminate the stress of employee management and business admin. <br className="d-none d-lg-block" /> Breeze through chaotic times.
                  </p>
                  <Link
                    to="elWhyUs"
                    spy={true}
                    smooth="easeInOutCubic"
                    offset={-80}
                    duration={2000}
                    css={`
                      cursor: pointer;
                    `}
                    className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-11"
                  >
                    Learn more{" "}
                    <i className="icon icon-tail-right font-weight-bold"></i>
                  </Link>
                </div>
              </Col>
              <Col
                lg="6"
                md="4"
                sm="7"
                className="align-self-sm-center"
              >
                <div
                  className="hero-img  z-index-1 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <img className="w-100 rounded-10" src={imgC2} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Hero;
