import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC1 from "../../../assets/image/why-us/help-to-streamline-your-business.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-28 pb-5 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="order-2 order-lg-1" lg="5" xs="10" data-aos="fade-right" data-aos-duration="600">
              <div className="content-img">
                <img src={imgC1} alt="" className="w-100 rounded-10" />
              </div>
              <div className="border-top mt-8 py-8 border-bottom">
                <figure class="text-end pb-0 mb-0">
                  <blockquote class="blockquote">
                    <p><strong>We contacted the Camelo team to have a few customizations for our business' needs. They were very willing to help and everything was set up perfectly.</strong></p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <strong>Craig M.</strong>, Business Owner, <cite title="Calypso Construction">Calypso Construction</cite>
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col className="order-1 order-lg-2 pl-xl-21" xs="10" lg="6">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-3 mb-11">
                  You and your business are in good hands
                </h2>
                <p className="gr-text-8">
                  With over hundreds of thousands of satisfied customers who have loved and trusted our product and service, Camelo is not just an app. It's a time-saving tool that smoothens your business workflow and performance while keeping everyone updated and involved.
                </p>
                <p className="gr-text-8">
                  Our team is constantly improving Camelo to introduce more features that make business owners, team leaders, and managers' lives easier.
                </p>
                <p className="gr-text-8">
                  Using Camelo is a way to love yourself, your teams, and your business. ❤️
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
