import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import {LazyLoadImage} from 'react-lazy-load-image-component';
import imgC from "../../../assets/image/why-us/reduce-stress-and-frustration.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-6 pt-lg-28 pb-7 bg-default-4">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              lg="5"
              className="order-2"
            >
              <div
                className="content-img text-right"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <LazyLoadImage
                  alt="Reduce stress and frustration, save time and money"
                  src={imgC}
                  className="w-100 rounded-10"
                />
              </div>
              <div
                className="border-top mt-8 py-8 border-bottom"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <figure class="text-end pb-0 mb-0">
                  <blockquote class="blockquote">
                    <p><strong>Finally, we don't have to be drowned in endless piles of paper schedules anymore. My team members are notified whenever there are changes in the schedule. No one misses their shifts anymore.</strong></p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <strong>Seth Smith</strong>, Business Co-Owner, <cite title="Source Title">Lee Harvey’s</cite>
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col xs="10" lg="6" xl="6" className="pr-xl-21 order-1">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-4 mb-11">
                  Reduce stress and frustration, save time and money
                </h2>
                <p className="gr-text-8 mb-11">
                  <h3 className="w-title gr-text-8">
                    Say goodbye to the cost of paper timesheets and schedules
                  </h3>
                  Camelo lets you create, edit, and publish schedules, as well as assigning tasks right from your smartphone and on-the-go. Repeat schedules, AI/auto-schedule, and edit previous schedule templates all the way you want.
                </p>
                <p className="gr-text-8 mb-11">
                  <h3 className="w-title gr-text-8">
                    No more back-and-forth emails asking for time off and leaves
                  </h3>
                  No more made-up excuses. Employees send time-off requests right on the app. Managers can see them all in one place, decide to approve or deny, and turn on the auto-approve feature when needed.
                </p>
                <p className="gr-text-8 mb-11">
                  <h3 className="w-title gr-text-8">
                    No more ringing around to find the right replacements for each shift
                  </h3>
                  Employees can swap shifts in unexpected situations, or volunteer for open shifts. And in case you want to find replacements yourself, it's super easy to select staff based on availability, roles, and skills.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
